body{
  height:100vh;
  /* background-image:url('https://browsecat.art/sites/default/files/youtube-thumbnail-background-129508-1982886-8653675.png');
  background-position:center;
  background-repeat:no-repeat; */
  background-color:#fff;
  position:relative;
  box-sizing:border-box;
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family:roboto;
  font-size:max(1.5vw,14px);
  color:#003459;
  width:100%;
  height:100%;
}

@keyframes headers{
  from{
    margin-left:-100%;
  }
  to{
    margin-left:0%;
  }
}

@keyframes fade-in{
  0% {opacity:0}
  25%{opacity:0}
  50%{opacity:.1}
  100%{opacity:1}
  /* 0%{margin-top:-100%}
  25%{margin-top:8%}
  35%{margin-top:-18%}
  50%{margin-top:5%}
  65%{margin-top:-8%}
  75%{margin-top:1%}
  85%{margin-top:0%}
  100%{margin-top:0%} */
  
}

/* 
white: #ffffff
darkest: #00171f
dark blue: #003459
light blue: #007ea7
very light blue: #00a8e8
*/

a{
  color:inherit;
}

.heading{
  color: #003459;
  margin:auto;
  text-align:center;
}

br{
  display:block;
  margin-bottom:0em;
}

/* @media(min-width:901px) and (max-width:1500px){

} */

@media(min-width:701px){
.info{
  display:flex;
  justify-content:space-between;
  column-gap:1em;
  color:white;
  margin:7rem auto;
  padding:0 6rem;
  height:fit-content;
  max-width:1100px;
  text-align:center;
 
}
.aboutImage{
  object-fit:cover;
  display:block;
  position:relative;
  width:20em;
  height:20em;
  max-width:300px;
  max-height:300px;
  border-radius:20px;
  box-shadow:3px 5px 2px 3px #00171f;
  animation:2s fade-in;
}
h1{
  font-size:min(3em,50px);
  color:#00171f;
}

h3{
  font-size:min(1.4em,23.3px);
  color:#003459;
  text-align:left;
}

.headers{
  text-align:left;
  position:relative;
  min-width:275px;
  max-width:25em;
  color:black;
  animation:1s headers;
}
}

@media(max-width:700px){
.info{
  margin-top:4em;
}
.aboutImage{
  object-fit:cover;
  display:block;
  width:max(30vw,200px);
  height:max(30vw,200px);
  align-self:flex-end;
  border-radius:20px;
  margin:10vw 40vw;
  box-shadow:3px 5px 2px 3px #00171f;
  animation:3s fade-in;
}

.headers{
  margin:1rem;
  text-align:left;
  position:relative;
  min-width:3.5rem;
  animation:1s fade-in;
}
h1{
  font-size:max(3em,35px);
  color:#00171f;
}

h3{
  font-size:max(1.4em,16.4px);
  color:#003459;
  text-align:left;
}
}

/*Projects*/

.projectParagraph{
  width:max(40vw,350px);
  margin:max(2em,25px) auto;
  border-radius:10px;
  padding:5px;
  box-shadow:3px 5px 5px 2px #00171f;
  color:#003459;
  font-weight:700;
}

.projectContainer{
  width:min-content;
  margin:2em auto;
  padding:3em 0 5em;
  display:flex;
  flex-wrap:wrap;
  column-gap:5em;
  row-gap:5em;
}

.jeopardyContainer{
  position:relative;
  width:max(40vw,350px);
  height:max(17vw,180px);
  background-image:url("./Jeopardy.png");
  background-position:center;
  background-color:rgba(21, 21, 23, 0.874);
  background-blend-mode:darken;
  background-size:cover;
  border-radius:2em;
  transition:.4s;
}

.jeopardyContainerEmpty{
  position:relative;
  width:max(40vw,350px);
  height:max(17vw,180px);
  background-image:url("./Jeopardy.png");
  background-position:center;
  background-size:cover;
  border-radius:2em;
  transition:.4s;
}

    .jeopardyContainerEmpty:hover{
      transform:scale(1.1);
    }



.bookContainer{
  position:relative;
  width:max(40vw,350px);
  height:max(17vw,180px);
  background-image:url("./Book-club.png");
  background-position:center;
  background-color:rgba(21, 21, 23, 0.874);
  background-blend-mode:darken;
  background-size:cover;
  border-radius:2em;
  transition:.4s;
}

.bookContainerEmpty{
  position:relative;
  width:max(40vw,350px);
  height:max(17vw,180px);
  background-image:url("./Book-club.png");
  background-position:center;
  background-size:cover;
  border-radius:2em;
  transition:.4s;
}

    .bookContainerEmpty:hover{
      transform:scale(1.1);
    }

    .bookContainerEmpty:hover .githubButton{
      color:#003459;
    }

    .bookContainerEmpty:hover .demoButton{
      background-color:#003459;
      color:#fff;
    }

.choreContainer{
  position:relative;
  background-image:url("./chore-tracker.png");
  background-position:center;
  background-color:rgba(21, 21, 23, 0.874);
  background-blend-mode:darken;
  background-size:cover;
  width:max(40vw,350px);
  height:max(17vw,180px);
  border-radius:2em;
  transition:.4s;
}

.choreContainerEmpty{
  position:relative;
  background-image:url("./chore-tracker.png");
  background-position:center;
  background-size:cover;
  width:max(40vw,350px);
  height:max(17vw,180px);
  border-radius:2em;
  transition:.4s;
}

    .choreContainerEmpty:hover{
      transform:scale(1.1);

    }

.modelContainer{
  position:relative;
  background-image:url("./basketball_model.png");
  background-position:center;
  background-color:rgba(21, 21, 23, 0.874);
  background-blend-mode:darken;
  background-size:cover;
  width:max(40vw,350px);
  height:max(17vw,180px);
  border-radius:2em;
  transition:.4s;
}

.modelContainerEmpty{
  position:relative;
  background-image:url("./basketball_model.png");
  background-position:center;
  background-size:cover;
  width:max(40vw,350px);
  height:max(17vw,180px);
  border-radius:2em;
  transition:.4s;
}

    .modelContainerEmpty:hover{
      transform:scale(1.1);

    }


.noInfo{
  transition:.4s;
  display:none;
}

.projectImg{
  width:auto;
  height:200px;
}

.projectName{
  margin:auto;
  padding:5px 20px;
  justify-content:center;
  color:#fff;
  font-size:max(2em,20px);
  backdrop-filter:blur(2px);
  border-radius:2em;
  width:fit-content;
}

.projectDescription{
  color:#fff;
  margin:.5em 1em;
  backdrop-filter:blur(2px);
  /* font-size:max(1em,14px) */
}

.demoButton{
  position:absolute;
  left:2vw;
  bottom:1em;
  outline:none;
  border:none;
  text-decoration:none;
  background:none;
  border-radius:5px;
  padding:2px;
  background-color:#fff;
  cursor:pointer;
  font-size:max(0.8em,12px);
}

    .demoButton:hover{
      transform:scale(1.1);
    }

.githubButton{
  position:absolute;
  bottom:.5em;
  right:1em;
  color:#fff;
  font-size:max(1.3em,15px);
  cursor:pointer;
}

    .githubButton:hover{
      transform:scale(1.1);
    }


/*Resume*/

@media(min-width:675px){
  .resumeContainer{
    position:relative;
    display:flex;
    flex-wrap:wrap;
    width:90%;
    margin:4em auto 2em ;
    background-color:#a7b1b5c9;
    border-radius:5px;
    padding-bottom:1em;
  }
  
  .resumeHeader{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    text-align:center;
    margin:1em 1em;
    margin-bottom:0;
    column-gap:1em;
    min-width:80%;
  }
  
  .resumeImg{
    position:relative;
    display:block;
    border-radius:20px;
    margin:1em 1em;
    margin-bottom:0px;
    width:min(13vw,300px);
    min-width:200px;
    height:auto;
  }
  
  .resumeName{
    align-items:flex-end;
    font-size:min(4em,80px);
    display:block;
    position:relative;
  }

  .levelTwoContainer{
    display:flex;
    justify-content:space-between;
    width:90%;
  }
  
  .infoContainer{
    margin:0.5em 1.5em;
    width:fit-content;
    max-width:220px;
    padding:0 1em;
  }

      .infoContainer h4{
        margin-left:1em;
        font-size:min(1em,16px)
      }

      .infoContainer p{
        margin:0;
        padding:0;
        font-size:min(1em,18px);
        line-height:1em;
      }

  .profile{
    position:relative;
    display:block;
    align-items:flex-end;
    width:60%;
    background-color:#a5aaac;
    border-radius:15px;
    padding:0 0.5em;
  }
  
  .resumeTitle{
    margin:0 3em;
  }

  .bottomContainer{
    display:flex;
    flex-direction:row;
    column-gap:2em;
    width:100%;
  }

  .employmentContainer{
    margin:0 1em;
    max-width:30em;
  }

      .jobContainer h5{
        font-size:.8em;
        margin:0;
        padding-left:10px;
        padding-bottom:10px;
        line-height:.8em;
        background-color:#011524;
        color:white;
        border-bottom-right-radius:10px;
        border-bottom-left-radius:10px;
      }

  .jobTitle{
    background-color:#011524;
    color:white;
    border-top-right-radius:10px;
    border-top-left-radius:10px;
    padding:10px;
    margin:0;
  }

  .jobTimeframe{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin:1em
  }

  .skillsContainer{
    margin:0 auto;
    align-items:flex-end;
    background-color:#a5aaac;
    padding:.1em 1em;
    border-radius:20px;
    margin:1em 1em;
  }

  .skills{
    /* display:flex;
    flex-wrap:wrap; */
  }

  .skills li{
    font-size:1.2em;
    margin-bottom:.2em;
  }

  .skills h1{
    text-align:center
  }

  .schoolContainer{
    background-color:#a5aaac;
    padding:.1em 1em;
    border-radius:20px;
    margin:1em 1em;
  }

  .school{
    display:flex;
    flex-direction:row;
    column-gap:1em;
  }

  .leftSide{
    width:70%;
  }

  .rightSide{
    display:block;
    position:relative;
    align-items:flex-end
  }

  .about{
    max-width:25em;
  }
}

@media(max-width:674px){

  @media(max-width:376px){
  .resumeContainer{
    position:relative;
    display:flex;
    flex-wrap:wrap;
    width:100%;
    height:100%;
    margin-top:3em;
    background-color:#a7b1b5c9;
    border-top-right-radius:5px;
    border-top-left-radius:5px;
    padding-bottom:1em;
  }
}

  @media(min-width:377px){
    .resumeContainer{
      position:relative;
      display:flex;
      flex-wrap:wrap;
      width:90%;
      margin:4em auto 2em;
      background-color:#a7b1b5c9;
      border-radius:5px;
      padding-bottom:1em;
    }
  }

  
  .resumeHeader{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    text-align:center;
    margin:1em auto;
    margin-bottom:0;
    column-gap:1em;
    min-width:80%;
  }

  
  .resumeImg{
    /* position:relative;
    display:block;
    margin:1em 1em;
    margin-bottom:0px;
    width:min(10vw,275px);
    min-width:175px;
    height:auto; */
    display:none
  }
  
  .resumeName{
    align-items:flex-end;
    font-size:min(4em,80px);
    display:block;
    position:relative;
    margin:0 auto 0;
  }

  .levelTwoContainer{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
  }
  
  .infoContainer{
    margin:0.5em 0 1em;
    width:fit-content;
    max-width:220px;
    padding:0 1em;
  }

      .infoContainer h4{
        margin-left:1em;
        font-size:min(1em,16px)
      }

      .infoContainer p{
        margin:0;
        padding:0;
        font-size:min(1em,18px);
        line-height:1em;
      }

  .profile{
    position:relative;
    width:90%;
    display:block;
    margin:auto;
    background-color:#a5aaac;
    border-radius:15px;
    padding:0 0.5em;
  }
  
  .resumeTitle{
    margin:0 3em;
  }

  .bottomContainer{
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    column-gap:2em;
    width:100%;
  }

  .employmentContainer{
    margin:0 1em;
    max-width:30em;
  }

      .jobContainer h5{
        font-size:.8em;
        margin:0;
        padding-left:10px;
        padding-bottom:10px;
        line-height:.8em;
        background-color:#011524;
        color:white;
        border-bottom-right-radius:10px;
        border-bottom-left-radius:10px;
      }

  .jobTitle{
    background-color:#011524;
    color:white;
    border-top-right-radius:10px;
    border-top-left-radius:10px;
    padding:10px;
    margin:0;
  }

  .jobTimeframe{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin:1em
  }

  .skillsContainer{
    margin:0 auto;
    align-items:flex-end;
    background-color:#a5aaac;
    padding:.1em 1em;
    border-radius:20px;
    margin:1em 1em;
  }

  .skills{
    /* display:flex;
    flex-wrap:wrap; */
  }

  .skills li{
    font-size:1.2em;
    margin-bottom:.2em;
  }

  .skills h1{
    text-align:center
  }

  .schoolContainer{
    background-color:#a5aaac;
    padding:.1em 1em;
    border-radius:20px;
    margin:1em 1em;
  }

  .school{
    display:flex;
    flex-direction:row;
    column-gap:1em;
  }

  .leftSide{
    width:70%;
  }

  .rightSide{
    display:block;
    position:relative;
    align-items:flex-end
  }

  .about{
    max-width:25em;
    margin:auto;
    padding:0 1em 0; 
  }
}



/*NavBars*/

  header{
  width:100%;
  padding-left:1em;
  top:0;
  display:flex;
  position:fixed;
  z-index:10;
  /* backdrop-filter:blur(10px) */
  background-color:rgb(172, 174, 190)
}



.nameContainer{
  height:100%;
  width:max(4em,500px);
}


.name{
  padding:.3em 0;
  margin:0;
  letter-spacing:.2em;
  color:#003459;
  min-width:250px;
  font-size:max(1em,20px);
  cursor:pointer;
}

    .name:hover{
      transform:scale(1.05);
      color:black;
      transition:.2s
    }
    

.socials{
  display:flex;
  flex-direction:row;
  column-gap:1em;
  padding:.1em;
}    

.socialLink{
  cursor:pointer;
  font-size:min(1.6em,25px);
  padding:0;
}

    .socialLink:hover{
      transform:scale(1.1);
    }

.navBar{
  width:100%;
  padding-right:2em;
  margin:auto;
  text-align:center;
  align-items:right;
  justify-content:right;
  display:flex;
  height:min-content;
}

.navBar .navLinks{
  display:flex;
  align-items:center;
  height:100%;
  margin:auto 5px;
  padding:0;
  gap:1.5em;
  list-style:none;
  text-align:right;
}

.navOption{
  margin:auto;
  width:100%;
  color:black;
  text-decoration: none;
  list-style-type:none;
  font-size:max(.8em,20px);
}

.navBars{
  color:black;
  font-size:1.65em;
  padding:.15em 0;
  cursor:pointer;
  display:none;

}

    .navBars:hover{
      transform:scale(1.05)
    }
    .navOption.active{
      scale:1.05;
      color:#003459;
      font-weight:bold;
    }

    .navOption:hover{
      color:#003459;
      transform:scale(1.05);
    }









/*DropDown Menu*/

.dropdownMenu{
  position:absolute;
  font-size:17px;
  right:2em;
  top:40px;
  width:150px;
  height:0;
  background:rgba(41, 42, 47, 0.76);
  box-shadow:1px 3px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter:blur(10em);
  border-radius:15px;
  overflow:hidden;
  display:none;
  transition:height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-bottom:1px;
  z-index:3;
}


 

.dropdownMenuOpen{
  position:absolute;
  font-size:17px;
  right:2em;
  top:40px;
  width:150px;
  height:0;
  background:rgb(239, 240, 241);
  box-shadow:1px 3px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter:blur(10em);
  border-radius:15px;
  overflow:hidden;
  height:150px;
  transition:height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-bottom:1px;
  z-index:3;
}

   .dropdownMenuOpen:NavLink{
    text-decoration:none
  }

  .dropdownMenu:NavLink{
    text-decoration:none
  }

.dropdownMenuOpen li{
  padding:0.7rem;
  display:flex;
  align-items:center;
  justify-content:center;
}

.dropdownMenu li{
  padding:0.7rem;
  display:flex;
  align-items:center;
  justify-content:center;
}







/*Responsive NavBar*/

@media(max-width:500px) {
  .navBar .navLinks{
    display:none;
  }

  .navBar .navBars{
    display:block;
  }

  .dropdownMenu{
    display:block;
  }

  
}

@media(max-width:376px){
  .dropdownMenu{
    left:0.5rem;
    width:unset;
  }
}
